import React, {useState} from 'react';
import './App.css';
import Card from './components/Card.js'
import NewCat from './components/NewCat.js'

const idGenerator = {
  counter: 7,
  get: function() {
    this.counter = this.counter + 1;
    console.log(this.counter)
    return this.counter
  }
}

const allCats = [
  { id: 1, title: 'Sir Puffles', text: 'A true Gentleman by day, ferocious killer by night', imageUrl: 'https://www.todayifoundout.com/wp-content/uploads/2015/07/scientist-cat.jpg' },
  { id: 2, title: 'Furrlicia', text: 'Lady in Black, mysterious and charming if she wants to be', imageUrl: 'https://www.our-happy-cat.com/images/padden-our-cats-name.jpg' },
  { id: 3, title: 'Pepper', text: 'Born on the streets, found her true calling as a couch warmer', imageUrl: 'https://www.catsofaustralia.com/images/Highlander%20Cat%2021.JPG' },
  { id: 4, title: 'Princess Paloma', text: 'Gentle and sweet and highly distinguished champion of many cat shows', imageUrl: 'https://pixy.org/download/4677051/' },
  { id: 5, title: 'Minka', text: 'A real cat, the best cat', imageUrl: 'https://i.imgur.com/7bEEi8t.jpg' },
  { id: 6, title: 'Salem', text: 'Is it magic or just cuteness?', imageUrl: 'https://face4pets.files.wordpress.com/2014/10/black-cat.jpg' },
  { id: 7,title: 'Lord Pawleroi', text: "Won't leave the house without a hat", imageUrl: "https://cdn.thisiswhyimbroke.com/images/hats-for-cats1.jpg" },
]


const App = () => {

  const [cats, setCats] = useState(allCats.slice(0,7));

  const deleteCat = (id) => {
    const deletedIndex = cats.findIndex(cat => cat && cat.id === id);
    if (deletedIndex < 0){
      return
    }
    const newCats = [...cats];
    newCats[deletedIndex] = undefined;
    setCats(newCats);
  }

  const addCat = (cat) => {
    const newCat = { id: idGenerator.get(), ...cat };
    setCats([newCat, ...cats])
  }


  return (
    <div className="App">
      <header className="App-header">
        <h1 className="main-title" data-test-id="main-title">
          Instacat
        </h1>
        <p style={{ margin: 10 }}><span className="flip icon">🐈</span>The most fabulous cats in the world<span className="icon">🐈</span></p>
        <NewCat addCat={addCat} />
      </header>
      <section data-test-id="cat-cards" className="cat-cards">
        {cats.map((cat) => cat && <Card {...cat} deleteCat={deleteCat} key={cat.id} />)}
      </section>
      <footer>
        <p><span data-test-id="footer-year">2019</span> © Created by Kitty McCatface</p>
      </footer>
    </div>
  );
}

export default App;
