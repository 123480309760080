import React, { useState } from 'react';

const Likes = ({ count }) => {
    const [likes, setLikes] = useState(count);

   return (
        <div data-test-id="likes-container" className="likes">
            <p data-test-id="likes-counter">{likes}</p>
            <button data-test-id="likes-button" onClick={() => setLikes(likes + 1)} className="like-button">{ likes > 0 ? "💜" : "🖤"}</button>
        </div>
    )
};

export default Likes;