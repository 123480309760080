import { useState } from 'react';

const useInput = (initialValue) => {
    const [value, setValue] = useState(initialValue);

    function clear(){
        setValue("");
    }
    
    function handleChange(ev){        
        setValue(ev.target.value);    
    }
    
    return [value, handleChange, clear];


}

export default useInput;