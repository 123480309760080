import React from 'react';
import Likes from './Likes';

const Card = ({ title, text, imageUrl, id, deleteCat }) => (
    <div data-test-id="cat-card" className="cat-card">
        <header className="cat-card--header">
            <h3 style={{ marginBottom: 'unset' }} data-test-id="cat-card-title">{title}</h3>
            <Likes count={0} />
        </header>
        <img data-test-id="cat-card-image" height="auto" width={200} src={imageUrl} alt={`portrait of ${title} looking absolutely fabulous`}/>
        <p data-test-id="cat-card-text" className="cat-card--text">{text}</p>
        <footer>
            <button data-test-id="cat-card-no-cat" className="no-cat" onClick={() => deleteCat(id)}>Not a cat?</button>
        </footer>
    </div>
);

export default Card;