import React from 'react';
import useInput from '../hooks/useInput';

const NewCat = ({ addCat }) => {
    const [title, onTitleChange, clearTitle] = useInput('');
    const [text, onTextChange, clearText] = useInput('');
    const [image, onImageChange, clearImage] = useInput('');



    const onSubmit = (e) => {
        e.preventDefault();
        setTimeout(() => addCat({ title, text, imageUrl: image }), 1000)
        
        clearTitle();
        clearText();
        clearImage();    
    }
    return (
        <form onSubmit={onSubmit} className="cat-form" data-test-id="cat-form">
            <div className="form-element">
                <label htmlFor="title">What's your cat's name?</label>
                <input type="text" id="title" name="title" value={title} onChange={onTitleChange} required/>
            </div>
            <div className="form-element">
                <label htmlFor="image">Link to a fabulous image of your cat:</label>
                <input type="url" id="image" name="image" value={image} onChange={onImageChange} required/>
            </div>
            <div className="form-element">
                <label htmlFor="text">Describe your cat:</label>
                <textarea rows={3} id="text" name="text" value={text} onChange={onTextChange} />
            </div>
            <div className="form-element">
                <input className="form-submit" type="submit" value="Add your cat 🐾" />
            </div>
        </form>
    )
}

export default NewCat;